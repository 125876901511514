@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/*
7D84B2
8E9DCC
D9DBF1
001021
2A324B
*/